@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* For Webkit browsers like Chrome, Safari */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #FFEB3B; /* updated to the secondary color */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #FFC107; /* updated to the primary color */
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #FFA000; /* a darker shade of yellow for hover effect */
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #FFC107 #FFEB3B; /* primary over secondary color */
}
.yellow-button {
  background-color: yellow;
  /* Add any additional styles you need */
}
